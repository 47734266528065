import React, { useState } from "react";

import Shayan from "../../assets/Images/shayan.jpg";
import TwitterLogo from "../../assets/Svgs/twitter.svg";
import LinkedinLogo from "../../assets/Svgs/linkedin.svg";
import InstagramLogo from "../../assets/Svgs/instagram.svg";
import LocationLogo from "../../assets/Svgs/location.svg";
import CallLogo from "../../assets/Svgs/call.svg";
import EmailLogo from "../../assets/Svgs/email.svg";
import Point from "../../assets/Svgs/point.svg";

import styled from "./index.module.css";

const About = (props) => {
  const [section, setSection] = useState("about");
  const WORK_EXPERIENCE = [
    {
      company_name: "UIIT",
      company_description:
        "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
      date: "2016 - 2018",
    },
    {
      company_name: "UIIT",
      company_description:
        "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
      date: "2016 - 2018",
    },
    {
      company_name: "Codinative",
      company_description:
        "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum",
      date: "2018 - now",
    },
    {
      company_name: "Codinative",
      company_description: "lorem ipsum lorem ipsum lorem ipsum ipsum",
      date: "2018 - now",
    },
  ];

  const CONTACT = [
    { icon: LocationLogo, value: "Iran - Tehran - 9" },
    { icon: CallLogo, value: "(+98) 9124585596", click: "tel:+989124585596" },
    {
      icon: EmailLogo,
      value: "sllayan@yahoo.com",
      click: "mailto:sllayan@yahoo.com",
    },
  ];

  const changeAboutTab = (id) => {
    aboutTabsAnimationClose(id);
    setTimeout(() => {
      setSection(id);
      aboutTabsAnimationOpen(id);
    }, 500);
  };
  const aboutTabsAnimationClose = (id) => {
    var intro_myself = document.getElementById("intro_myself");
    var aab = document.getElementById("aab");
    var aeb = document.getElementById("aeb");
    var acb = document.getElementById("acb");

    intro_myself.style.transition = "0.5s";
    aab.style.transition = "0.5s";
    aeb.style.transition = "0.5s";
    acb.style.transition = "0.5s";

    intro_myself.style.opacity = 0;
    aab.style.opacity = 0;
    aeb.style.opacity = 0;
    acb.style.opacity = 0;
  };
  const aboutTabsAnimationOpen = (id) => {
    var intro_myself = document.getElementById("intro_myself");
    var aab = document.getElementById("aab");
    var aeb = document.getElementById("aeb");
    var acb = document.getElementById("acb");

    intro_myself.style.transition = "0.5s";
    aab.style.transition = "0.5s";
    aeb.style.transition = "0.5s";
    acb.style.transition = "0.5s";

    intro_myself.style.opacity = 1;
    aab.style.opacity = 1;
    aeb.style.opacity = 1;
    acb.style.opacity = 1;
  };

  return (
    <div className={styled.about} id={props.id}>
      <div className={styled.about_box}>
        <div className={styled.ab_head_box}>
          <p
            className={[styled.ab_hb_title, styled.disable_highlight].join(" ")}
          >
            About
          </p>
        </div>
        <div className={styled.ab_body_box}>
          <div className={styled.ab_bb_base_box}>
            <div id="box" className={styled.ab_bb_bb_base_box_2}>
              <div
                id="intro_myself"
                className={
                  section === "about"
                    ? styled.ab_bb_bb_bb2_intro_myself
                    : styled.ab_bb_bb_bb2_intro_myself_change
                }
              >
                <img
                  src={Shayan}
                  alt="shayan"
                  className={[
                    section === "about"
                      ? styled.ab_bb_bb_bb2_im_image
                      : styled.ab_bb_bb_bb2_im_image_change,
                    styled.disable_highlight,
                  ].join(" ")}
                />
                <div
                  className={
                    section === "about"
                      ? styled.ab_bb_bb_bb2_im_text_box
                      : styled.ab_bb_bb_bb2_im_text_box_change
                  }
                >
                  <p
                    className={[
                      styled.ab_bb_bb_bb2_im_tb_name,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Shayan Hosseinali
                  </p>
                  <p
                    className={[
                      styled.ab_bb_bb_bb2_im_tb_title,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Web Developer | Designer
                  </p>
                </div>
              </div>

              <div
                id="aab"
                className={[
                  styled.ab_bb_bb_bb2_explane_box,
                  section === "about" ? "" : styled.dont_show,
                ].join(" ")}
              >
                {/* {section === "about" && ( */}
                <React.Fragment>
                  <p
                    className={[
                      styled.ab_bb_bb_bb2_eb_explain_title,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Brief explanation
                  </p>
                  <p
                    className={[
                      styled.ab_bb_bb_bb2_eb_explain_text,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Hey guys! This is Shayan. 22 years ago, I was born in Iran
                    and have been raised there! I'm a native of the capital,
                    Tehran. I'm working as a website developer and I have
                    professional experience in this field for 5 years!
                  </p>
                  <div className={styled.ab_bb_bb_bb2_eb_social_box}>
                    <div
                      className={styled.ab_bb_bb_bb2_eb_sb_social}
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/shayan-hosseinali-79852b1bb/",
                          "_blank"
                        )
                      }
                    >
                      <img
                        src={LinkedinLogo}
                        alt="sc"
                        className={[
                          styled.ab_bb_bb_bb2_eb_sb_s_logo,
                          styled.disable_highlight,
                        ].join(" ")}
                      />
                    </div>
                    <div
                      className={styled.ab_bb_bb_bb2_eb_sb_social}
                      onClick={() =>
                        window.open("https://twitter.com/sllayan_", "_blank")
                      }
                    >
                      <img
                        src={TwitterLogo}
                        alt="sc"
                        className={[
                          styled.ab_bb_bb_bb2_eb_sb_s_logo,
                          styled.disable_highlight,
                        ].join(" ")}
                      />
                    </div>
                    <div
                      className={styled.ab_bb_bb_bb2_eb_sb_social}
                      onClick={() =>
                        window.open("https://instagram.com/sllayan", "_blank")
                      }
                    >
                      <img
                        src={InstagramLogo}
                        alt="sc"
                        className={[
                          styled.ab_bb_bb_bb2_eb_sb_s_logo,
                          styled.disable_highlight,
                        ].join(" ")}
                      />
                    </div>
                  </div>
                </React.Fragment>
                {/* )} */}
              </div>
              <div
                id="aeb"
                className={[
                  styled.ab_bb_bb_bb2_experience_myself,
                  section === "experience" ? "" : styled.dont_show,
                ].join(" ")}
              >
                {/* {section === "experience" && ( */}
                <React.Fragment>
                  <p
                    className={[
                      styled.ab_bb_bb_bb2_em_title,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Work experience
                  </p>
                  {WORK_EXPERIENCE.map((item, i) => {
                    return (
                      <div key={i} className={styled.ab_bb_bb_bb2_em_body_box}>
                        <div className={styled.ab_bb_bb_bb2_em_bb_title}>
                          <img
                            src={Point}
                            alt="point"
                            className={[
                              styled.ab_bb_bb_bb2_em_bb_t_point,
                              styled.disable_highlight,
                            ].join(" ")}
                          />
                          <p
                            className={[
                              styled.ab_bb_bb_bb2_em_bb_t_text,
                              styled.disable_highlight,
                            ].join(" ")}
                          >
                            {item.company_name}
                          </p>
                        </div>
                        <div
                          className={[
                            styled.ab_bb_bb_bb2_em_bb_description,
                            styled.disable_highlight,
                          ].join(" ")}
                        >
                          {item.company_description}
                        </div>

                        <p
                          className={[
                            styled.ab_bb_bb_bb2_em_bb_t_date,
                            styled.disable_highlight,
                          ].join(" ")}
                        >
                          {item.date}
                        </p>
                        <div className={styled.ab_bb_bb_bb2_em_bb_d_line}></div>
                      </div>
                    );
                  })}
                </React.Fragment>
                {/* )} */}
              </div>
              <div
                id="acb"
                className={[
                  styled.ab_bb_bb_bb2_contact_myself,
                  section === "contact" ? "" : styled.dont_show,
                ].join(" ")}
              >
                {/* {section === "contact" && ( */}
                <React.Fragment>
                  <p
                    className={[
                      styled.ab_bb_bb_bb2_cm_title,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Contact
                  </p>
                  <div className={styled.ab_bb_bb_bb2_cm_body_box}>
                    {CONTACT.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className={styled.ab_bb_bb_bb2_cm_bb_contact_box}
                        >
                          <img
                            src={item.icon}
                            alt={i}
                            className={[
                              styled.ab_bb_bb_bb2_cm_bb_cb_icon,
                              styled.disable_highlight,
                            ].join(" ")}
                          />
                          <p
                            className={[
                              styled.ab_bb_bb_bb2_cm_bb_cb_text,
                              styled.disable_highlight,
                            ].join(" ")}
                            onClick={() => window.open(item.click, "_self")}
                          >
                            {item.value}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div className={styled.ab_bb_bb_bb2_cm_bb_button_box}>
                    <button
                      className={[
                        styled.ab_bb_bb_bb2_cm_bb_button,
                        styled.disable_highlight,
                      ].join(" ")}
                      onClick={() => window.open("tel:+989124585596", "_self")}
                    >
                      Work together
                    </button>
                  </div>
                </React.Fragment>
                {/* )} */}
              </div>

              <div className={styled.ab_bb_bb_bb2_toolbar_box}>
                <div
                  className={[
                    styled.ab_bb_bb_bb2_tb_option,
                    styled.disable_highlight,
                    section === "about"
                      ? styled.ab_bb_bb_bb2_tb_option_active
                      : "",
                  ].join(" ")}
                  style={
                    section === "about"
                      ? {
                          borderRadius: "0 0 0 10px",
                        }
                      : {}
                  }
                  onClick={() => changeAboutTab("about")}
                >
                  About
                </div>
                <div
                  className={[
                    styled.ab_bb_bb_bb2_tb_option,
                    styled.disable_highlight,
                    section === "experience"
                      ? styled.ab_bb_bb_bb2_tb_option_active
                      : "",
                  ].join(" ")}
                  onClick={() => changeAboutTab("experience")}
                >
                  Experience
                </div>
                <div
                  className={[
                    styled.ab_bb_bb_bb2_tb_option,
                    styled.disable_highlight,
                    section === "contact"
                      ? styled.ab_bb_bb_bb2_tb_option_active
                      : "",
                  ].join(" ")}
                  style={
                    section === "contact"
                      ? {
                          borderRadius: "0 0 10px 0",
                        }
                      : {}
                  }
                  onClick={() => changeAboutTab("contact")}
                >
                  Contact
                </div>
              </div>
            </div>
          </div>

          <div className={styled.outer_box_1}></div>
          <div className={styled.outer_box_2}></div>
          <div className={styled.outer_box_3}></div>
          <div className={styled.outer_box_4}></div>
        </div>
      </div>
    </div>
  );
};

export default About;
