/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";

import StarFull from "../../assets/Svgs/star-full.svg";
import StarEmpty from "../../assets/Svgs/star-empty.svg";
import Refresh from "../../assets/Svgs/refresh.svg";

import styled from "./index.module.css";

const Entertainment = (props) => {
  const [plate1, setPlate1] = useState([
    { id: 7, name: "pink" },
    { id: 6, name: "rebeccapurple" },
    { id: 5, name: "blue" },
    { id: 4, name: "yellow" },
    { id: 3, name: "green" },
    { id: 2, name: "orange" },
    { id: 1, name: "red" },
  ]);
  const [plate2, setPlate2] = useState([]);
  const [plate3, setPlate3] = useState([]);
  const [activePlate, setActivePlate] = useState("");
  const [level, setLevel] = useState(0);
  const [star, setStar] = useState(3);
  const [win, setWin] = useState(false);

  useEffect(() => {
    if (level > 100) {
      setStar(2);
    }
    if (level > 150) {
      setStar(1);
    }
  }, [level]);
  useEffect(() => {
    if (plate3.length === 7) {
      setWin(true);
    }
  }, [plate3]);

  const plate1Handler = () => {
    if (plate1.length !== 0 || activePlate !== "") {
      plate1.map((item) => {
        if (activePlate.id === item.id) {
          setActivePlate("");
        }
      });

      if (activePlate === "") {
        setActivePlate(plate1[0]);
        // setPlate1(plate1.filter((item) => item.id !== plate1[0].id));
      } else {
        if (plate1.length === 0) {
          setPlate1((prevState) => [activePlate, ...prevState]);
          setPlate2(plate2.filter((item) => item.name !== activePlate.name));
          setPlate3(plate3.filter((item) => item.name !== activePlate.name));
          setLevel(level + 1);
          setActivePlate("");
        } else if (activePlate.id > plate1[0].id) {
          setPlate1((prevState) => [activePlate, ...prevState]);
          setPlate2(plate2.filter((item) => item.name !== activePlate.name));
          setPlate3(plate3.filter((item) => item.name !== activePlate.name));
          setLevel(level + 1);
          setActivePlate("");
        }
      }
    }
  };
  const plate2Handler = () => {
    if (plate2.length !== 0 || activePlate !== "") {
      plate2.map((item) => {
        if (activePlate.id === item.id) {
          setActivePlate("");
        }
      });

      if (activePlate === "") {
        setActivePlate(plate2[0]);
        // setPlate2(plate2.filter((item) => item.id !== plate2[0].id));
      } else {
        if (plate2.length === 0) {
          setPlate2((prevState) => [activePlate, ...prevState]);
          setPlate1(plate1.filter((item) => item.name !== activePlate.name));
          setPlate3(plate3.filter((item) => item.name !== activePlate.name));
          setLevel(level + 1);
          setActivePlate("");
        } else if (activePlate.id > plate2[0].id) {
          setPlate2((prevState) => [activePlate, ...prevState]);
          setPlate1(plate1.filter((item) => item.name !== activePlate.name));
          setPlate3(plate3.filter((item) => item.name !== activePlate.name));
          setLevel(level + 1);
          setActivePlate("");
        }
      }
    }
  };
  const plate3Handler = () => {
    if (plate3.length !== 0 || activePlate !== "") {
      plate3.map((item) => {
        if (activePlate.id === item.id) {
          setActivePlate("");
        }
      });
      if (activePlate === "") {
        setActivePlate(plate3[0]);
        // setPlate3(plate3.filter((item) => item.id !== plate3[0].id));
      } else {
        if (plate3.length === 0) {
          setPlate3((prevState) => [activePlate, ...prevState]);
          setPlate1(plate1.filter((item) => item.name !== activePlate.name));
          setPlate2(plate2.filter((item) => item.name !== activePlate.name));
          setLevel(level + 1);
          setActivePlate("");
        } else if (activePlate.id > plate3[0].id) {
          setPlate3((prevState) => [activePlate, ...prevState]);
          setPlate1(plate1.filter((item) => item.name !== activePlate.name));
          setPlate2(plate2.filter((item) => item.name !== activePlate.name));
          setLevel(level + 1);
          setActivePlate("");
        }
      }
    }
  };

  const refreshHandler = () => {
    setPlate1([
      { id: 7, name: "pink" },
      { id: 6, name: "rebeccapurple" },
      { id: 5, name: "blue" },
      { id: 4, name: "yellow" },
      { id: 3, name: "green" },
      { id: 2, name: "orange" },
      { id: 1, name: "red" },
    ]);
    setPlate2([]);
    setPlate3([]);
    setActivePlate("");
    setLevel(0);
    setStar(3);
    setWin(false);
  };

  return (
    <div className={styled.entertainment} id={props.id}>
      <div className={styled.entertainment_box}>
        <div className={styled.eb_head_box}>
          <p
            className={[styled.eb_hb_title, styled.disable_highlight].join(" ")}
          >
            Entertainment
          </p>
        </div>
        <div className={styled.eb_body_box}>
          <div
            className={[
              styled.eb_result_box,
              win ? styled.win_disable : "",
            ].join(" ")}
          >
            <div className={styled.eb_rb_star_box}>
              <img
                src={star >= 1 ? StarFull : StarEmpty}
                alt="star"
                className={[
                  styled.eb_rb_sb_star,
                  styled.disable_highlight,
                ].join(" ")}
              />
              <img
                src={star >= 2 ? StarFull : StarEmpty}
                alt="star"
                className={[
                  styled.eb_rb_sb_star,
                  styled.disable_highlight,
                ].join(" ")}
              />
              <img
                src={star >= 3 ? StarFull : StarEmpty}
                alt="star"
                className={[
                  styled.eb_rb_sb_star,
                  styled.disable_highlight,
                ].join(" ")}
              />
            </div>
            <div className={styled.eb_rb_num_of_move_box}>
              <p
                className={[
                  styled.eb_rb_nomb_number,
                  styled.disable_highlight,
                ].join(" ")}
              >
                {level} Moves
              </p>
            </div>
          </div>

          <div
            className={[
              styled.eb_active_box,
              win ? styled.win_disable : "",
            ].join(" ")}
          >
            <div
              className={[styled.eb_ab_column, styled.disable_highlight].join(
                " "
              )}
            >
              {plate1.map((item, i) => {
                if (activePlate.id === item.id) {
                  return (
                    <div
                      key={i}
                      className={[
                        activePlate.name === "pink"
                          ? styled.pink
                          : activePlate.name === "rebeccapurple"
                          ? styled.rebeccapurple
                          : activePlate.name === "blue"
                          ? styled.blue
                          : activePlate.name === "yellow"
                          ? styled.yellow
                          : activePlate.name === "green"
                          ? styled.green
                          : activePlate.name === "orange"
                          ? styled.orange
                          : activePlate.name === "red"
                          ? styled.red
                          : "",
                        styled.disable_highlight,
                      ].join(" ")}
                    ></div>
                  );
                }
              })}
            </div>
            <div
              className={[styled.eb_ab_column, styled.disable_highlight].join(
                " "
              )}
            >
              {plate2.map((item, i) => {
                if (activePlate.id === item.id) {
                  return (
                    <div
                      key={i}
                      className={[
                        activePlate.name === "pink"
                          ? styled.pink
                          : activePlate.name === "rebeccapurple"
                          ? styled.rebeccapurple
                          : activePlate.name === "blue"
                          ? styled.blue
                          : activePlate.name === "yellow"
                          ? styled.yellow
                          : activePlate.name === "green"
                          ? styled.green
                          : activePlate.name === "orange"
                          ? styled.orange
                          : activePlate.name === "red"
                          ? styled.red
                          : "",
                        styled.disable_highlight,
                      ].join(" ")}
                    ></div>
                  );
                }
              })}
            </div>
            <div
              className={[styled.eb_ab_column, styled.disable_highlight].join(
                " "
              )}
            >
              {plate3.map((item, i) => {
                if (activePlate.id === item.id) {
                  return (
                    <div
                      key={i}
                      className={[
                        activePlate.name === "pink"
                          ? styled.pink
                          : activePlate.name === "rebeccapurple"
                          ? styled.rebeccapurple
                          : activePlate.name === "blue"
                          ? styled.blue
                          : activePlate.name === "yellow"
                          ? styled.yellow
                          : activePlate.name === "green"
                          ? styled.green
                          : activePlate.name === "orange"
                          ? styled.orange
                          : activePlate.name === "red"
                          ? styled.red
                          : "",
                        styled.disable_highlight,
                      ].join(" ")}
                    ></div>
                  );
                }
              })}
            </div>
          </div>

          <div
            className={[
              styled.eb_hobby_box,
              win ? styled.win_disable : "",
            ].join(" ")}
          >
            <div
              className={[
                styled.eb_hb_each_tree_box,
                styled.disable_highlight,
              ].join(" ")}
            >
              <div
                className={[
                  styled.eb_hb_etb_column_box,
                  styled.disable_highlight,
                ].join(" ")}
                onClick={plate1Handler}
              >
                <div className={styled.eb_hb_etb_cb_main_column}></div>
                {plate1.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={[
                        styled.disable_highlight,
                        item.name === "pink"
                          ? styled.pink
                          : item.name === "rebeccapurple"
                          ? styled.rebeccapurple
                          : item.name === "blue"
                          ? styled.blue
                          : item.name === "yellow"
                          ? styled.yellow
                          : item.name === "green"
                          ? styled.green
                          : item.name === "orange"
                          ? styled.orange
                          : item.name === "red"
                          ? styled.red
                          : "",
                        activePlate.name === item.name ? styled.hide : "",
                      ].join(" ")}
                    ></div>
                  );
                })}
              </div>
              <div className={styled.eb_hb_etb_roof_box}>
                <div className={styled.eb_hb_etb_rb_roof}></div>
              </div>
            </div>

            <div
              className={[
                styled.eb_hb_each_tree_box,
                styled.disable_highlight,
              ].join(" ")}
            >
              <div
                className={[
                  styled.eb_hb_etb_column_box,
                  styled.disable_highlight,
                ].join(" ")}
                onClick={plate2Handler}
              >
                <div className={styled.eb_hb_etb_cb_main_column}></div>
                {plate2.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={[
                        styled.disable_highlight,
                        item.name === "pink"
                          ? styled.pink
                          : item.name === "rebeccapurple"
                          ? styled.rebeccapurple
                          : item.name === "blue"
                          ? styled.blue
                          : item.name === "yellow"
                          ? styled.yellow
                          : item.name === "green"
                          ? styled.green
                          : item.name === "orange"
                          ? styled.orange
                          : item.name === "red"
                          ? styled.red
                          : "",
                        activePlate.name === item.name ? styled.hide : "",
                      ].join(" ")}
                    ></div>
                  );
                })}
              </div>
              <div className={styled.eb_hb_etb_roof_box}>
                <div className={styled.eb_hb_etb_rb_roof}></div>
              </div>
            </div>

            <div
              className={[
                styled.eb_hb_each_tree_box,
                styled.disable_highlight,
              ].join(" ")}
            >
              <div
                className={[
                  styled.eb_hb_etb_column_box,
                  styled.disable_highlight,
                ].join(" ")}
                onClick={plate3Handler}
              >
                <div className={styled.eb_hb_etb_cb_main_column}></div>
                {plate3.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={[
                        styled.disable_highlight,
                        item.name === "pink"
                          ? styled.pink
                          : item.name === "rebeccapurple"
                          ? styled.rebeccapurple
                          : item.name === "blue"
                          ? styled.blue
                          : item.name === "yellow"
                          ? styled.yellow
                          : item.name === "green"
                          ? styled.green
                          : item.name === "orange"
                          ? styled.orange
                          : item.name === "red"
                          ? styled.red
                          : "",
                        activePlate.name === item.name ? styled.hide : "",
                      ].join(" ")}
                    ></div>
                  );
                })}
              </div>
              <div className={styled.eb_hb_etb_roof_box}>
                <div className={styled.eb_hb_etb_rb_roof}></div>
              </div>
            </div>
          </div>

          <div
            className={[
              styled.eb_refresh_box,
              win ? styled.win_disable : "",
            ].join(" ")}
          >
            <img
              src={Refresh}
              alt="star"
              className={[
                styled.eb_rb_refrech_icon,
                styled.disable_highlight,
              ].join(" ")}
              onClick={refreshHandler}
            />
          </div>

          {win && (
            <div className={styled.win_box}>
              <div className={styled.eb_rb_star_box}>
                <img
                  src={star >= 1 ? StarFull : StarEmpty}
                  alt="star"
                  className={[
                    styled.eb_rb_sb_star,
                    styled.disable_highlight,
                  ].join(" ")}
                />
                <img
                  src={star >= 2 ? StarFull : StarEmpty}
                  alt="star"
                  className={[
                    styled.eb_rb_sb_star,
                    styled.disable_highlight,
                  ].join(" ")}
                />
                <img
                  src={star >= 3 ? StarFull : StarEmpty}
                  alt="star"
                  className={[
                    styled.eb_rb_sb_star,
                    styled.disable_highlight,
                  ].join(" ")}
                />
              </div>
              <p
                className={[styled.wb_text, styled.disable_highlight].join(" ")}
              >
                * You Win \(-_-)/ *
              </p>
              <p
                className={[styled.wb_score, styled.disable_highlight].join(
                  " "
                )}
              >
                move : {level}
              </p>
              <img
                src={Refresh}
                alt="star"
                className={[
                  styled.eb_rb_refrech_icon,
                  styled.disable_highlight,
                ].join(" ")}
                onClick={refreshHandler}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Entertainment;
