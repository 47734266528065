import React from "react";

// import WorkExperiences from "./WorkExperience";
// import ProgrammingSkills from "./ProgrammingSkills";
// import SoftwareSkills from "./SoftwareSkills";
import Knowledge from "./Knowledge";
import Info from "./Info";

import styled from "./index.module.css";

const Skills = (props) => {
  return (
    <div className={styled.skills} id={props.id}>
      <div className={styled.skills_box}>
        <div className={styled.sb_head_box}>
          <p
            className={[styled.sb_tb_title, styled.disable_highlight].join(" ")}
          >
            Skills
          </p>
        </div>

        <div className={styled.sb_body_box}>
          <Knowledge />
          <Info />
          {/* <WorkExperiences /> */}
          {/* <ProgrammingSkills /> */}
          {/* <SoftwareSkills /> */}
        </div>
      </div>
    </div>
  );
};

export default Skills;
