import React from "react";

import Shayan from "../../assets/Svgs/shayan-white.svg";
// import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import styled from "./index.module.css";

const Footer = (props) => {
  // const [motion, setMotion] = useState(false);
  // useScrollPosition(({ prevPos, currPos }) => {
  //   if (currPos.y < -4180) {
  //     setMotion(true);
  //   } else if (currPos.y > -4180) {
  //     setMotion(false);
  //   }
  // });

  return (
    <div className={styled.footer}>
      <div className={styled.footer_box}>
        <div className={styled.fb_first_box}></div>
        <div className={styled.fb_second_box}></div>
        <div className={styled.fb_third_box}></div>

        <div className={styled.fb_shayan_box}>
          <img
            src={Shayan}
            alt="s"
            className={[styled.fb_sb_logo, styled.disable_highlight].join(" ")}
          />
          <p
            className={[styled.fb_sb_name, styled.disable_highlight].join(" ")}
          >
            Sllayan
          </p>
        </div>
        <div className={styled.fb_text_box}>
          <p
            className={[styled.fb_tb_text, styled.disable_highlight].join(" ")}
          >
            Find inner programmer of yourself,
          </p>
          <p
            className={[styled.fb_tb_text, styled.disable_highlight].join(" ")}
          >
            The outer one is here
          </p>
        </div>

        <div className={[styled.fb_cr_box, styled.disable_highlight].join(" ")}>
          Copy right &copy; by Sllayan {new Date().getFullYear()} | all rights
          reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
