import React from "react";

import Beginning from "../../components/Beginning";
import Skills from "../../components/Skills";
import Entertainment from "../../components/Entertainment";
import AboutMe from "../../components/About";
import Amaze from "../../components/Amaze";
import Contact from "../../components/Contact/index";

import styled from "./index.module.css";

const Home = (props) => {
  return (
    <div className={styled.home_page}>
      <div className={styled.home_page_box}>
        <Beginning id="beginning" />
        <Skills id="skills" />
        <AboutMe id="about" />
        <Entertainment id="entertainment" />
        <Amaze id="amaze" />
        <Contact id="contact" />
      </div>
    </div>
  );
};

export default Home;
