import React, { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import Up from "../../assets/Svgs/up-black.svg";
import Call from "../../assets/Svgs/call-black.svg";

import styled from "./index.module.css";

const GoUp = (props) => {
  const [motion, setMotion] = useState(false);
  const [mobile, setMobile] = useState(false);

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -420) {
      setMotion(true);
    } else if (currPos.y > -420) {
      setMotion(false);
    }
    if (currPos.y < -3200) {
      setMotion(false);
    }
  });

  const callClickHandler = () => {
    window.open("tel:+989124585596", "_self");
    setMobile(false);
  };

  const goUpHandler = () => {
    setMobile(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      className={[styled.go_up, motion ? styled.show : styled.hide].join(" ")}
    >
      <div className={styled.go_up_box}>
        <div className={styled.gub_up_box} onClick={goUpHandler}>
          <img
            src={Up}
            alt="up"
            className={[styled.gub_ub_icon, styled.disable_highlight].join(" ")}
          />
        </div>
        <div className={styled.gub_call_box}>
          <div
            className={[
              styled.gub_cb_icon_box,
              mobile ? styled.gub_cb_icon_box_open : "",
            ].join(" ")}
            onClick={() => setMobile(!mobile)}
          >
            <img
              src={Call}
              alt="up"
              className={[styled.gub_cb_ib_icon, styled.disable_highlight].join(
                " "
              )}
            />
            {mobile && (
              <p
                className={[
                  styled.gub_cb_ib_mobile,
                  styled.disable_highlight,
                ].join(" ")}
                onClick={callClickHandler}
              >
                (+98)9124585596
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoUp;
