import React, { useState } from "react";

import InstagramLogo from "../../assets/Svgs/instagram.svg";
import LinkedinLogo from "../../assets/Svgs/linkedin.svg";
import WhatsAppLogo from "../../assets/Svgs/whatsapp.svg";
import TwitterLogo from "../../assets/Svgs/twitter.svg";
import GitHubLogo from "../../assets/Svgs/github.svg";
import TelegramLogo from "../../assets/Svgs/telegram.svg";
import VirgoolLogo from "../../assets/Svgs/virgool.svg";
import Timothy from "../../assets/Svgs/timothy.svg";

import styled from "./index.module.css";

const Contact = (props) => {
  const [messageData, setMessageData] = useState({
    name: "",
    subject: "",
    email: "",
    phone: "",
    message: "",
  });

  const fillMessageBoxesHandler = (e) => {
    var fieldId = e.target.id;
    var fieldValue = e.target.value;
    // eslint-disable-next-line array-callback-return
    Object.keys(messageData).map((item) => {
      if (item === fieldId) {
        setMessageData((prevState) => ({
          ...prevState,
          [fieldId]: fieldValue,
        }));
      }
    });
  };

  const sendMessageHandler = () => {
    // console.log(orderData);
    //CALL_API
    setMessageData({
      name: "",
      subject: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className={styled.contact} id={props.id}>
      <div className={styled.contact_box}>
        <div className={styled.cb_head_box}>
          <p
            className={[styled.cb_hb_title, styled.disable_highlight].join(" ")}
          >
            Contact
          </p>
        </div>

        <div className={styled.cb_body_box}>
          <div className={styled.cb_bb_detail_box}>
            <div className={styled.cb_bb_db_thimoty_box}>
              <img
                src={Timothy}
                alt="shayan"
                className={[
                  styled.cb_bb_db_tb_logo,
                  styled.disable_highlight,
                ].join(" ")}
              />
              <div className={styled.cb_bb_db_tb_intro_box}>
                <p
                  className={[
                    styled.cb_bb_db_tb_ib_title,
                    styled.disable_highlight,
                  ].join(" ")}
                >
                  Hi everyone,
                </p>
                <p
                  className={[
                    styled.cb_bb_db_tb_ib_text,
                    styled.disable_highlight,
                  ].join(" ")}
                >
                  My name's Timothy and I'm Shayan's assistant! I give him ideas
                  in the field of design and graphics! Let's join us to creat
                  your project in the best way!
                </p>
              </div>
            </div>
            <div className={styled.cb_bb_db_social_box}>
              <div className={styled.cb_bb_db_sb_social}>
                <div
                  className={styled.cb_bb_db_sb_s_inner}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/in/shayan-hosseinali-79852b1bb/",
                      "_blank"
                    )
                  }
                >
                  <img
                    src={LinkedinLogo}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
              <div className={styled.cb_bb_db_sb_social}>
                <div
                  className={styled.cb_bb_db_sb_s_inner}
                  onClick={() =>
                    window.open("https://github.com/Sllayan", "_blank")
                  }
                >
                  <img
                    src={GitHubLogo}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
              <div className={styled.cb_bb_db_sb_social}>
                <div
                  className={styled.cb_bb_db_sb_s_inner}
                  onClick={() =>
                    window.open("https://twitter.com/sllayan_", "_blank")
                  }
                >
                  <img
                    src={TwitterLogo}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
              <div className={styled.cb_bb_db_sb_social}>
                <div
                  className={styled.cb_bb_db_sb_s_inner}
                  onClick={() => window.open("https://t.me/sllayan", "_blank")}
                >
                  <img
                    src={TelegramLogo}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_1,
                ].join(" ")}
              >
                <div className={styled.cb_bb_db_sb_s_inner}>
                  <p
                    className={[
                      styled.cb_bb_db_sb_s_i_social_text,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Find
                  </p>
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_1,
                ].join(" ")}
              >
                <div className={styled.cb_bb_db_sb_s_inner}>
                  <p
                    className={[
                      styled.cb_bb_db_sb_s_i_social_text,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    me
                  </p>
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_1,
                ].join(" ")}
              >
                <div className={styled.cb_bb_db_sb_s_inner}>
                  <p
                    className={[
                      styled.cb_bb_db_sb_s_i_social_text,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    in
                  </p>
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_1,
                ].join(" ")}
              >
                <div className={styled.cb_bb_db_sb_s_inner}>
                  <p
                    className={[
                      styled.cb_bb_db_sb_s_i_social_text,
                      styled.disable_highlight,
                    ].join(" ")}
                  >
                    Social
                  </p>
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_2,
                ].join(" ")}
              >
                <div
                  className={styled.cb_bb_db_sb_s_inner}
                  onClick={() =>
                    window.open("https://instagram.com/sllayan", "_blank")
                  }
                >
                  <img
                    src={InstagramLogo}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_2,
                ].join(" ")}
              >
                <div
                  className={styled.cb_bb_db_sb_s_inner}
                  onClick={() => window.open("tel:+989124585596", "_self")}
                >
                  <img
                    src={WhatsAppLogo}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_2,
                ].join(" ")}
              >
                <div
                  className={styled.cb_bb_db_sb_s_inner}
                  onClick={() =>
                    window.open("https://virgool.io/@sllayan", "_blank")
                  }
                >
                  <img
                    src={VirgoolLogo}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
              <div
                className={[
                  styled.cb_bb_db_sb_social,
                  styled.cb_bb_db_sb_social_location_2,
                ].join(" ")}
              >
                <div
                  className={[
                    styled.cb_bb_db_sb_s_inner,
                    styled.cb_bb_db_sb_s_inner_thimoty,
                  ].join(" ")}
                  onClick={() =>
                    window.open("https://instagram.com/devsllayan", "_blank")
                  }
                >
                  <img
                    src={Timothy}
                    alt="sc"
                    className={[
                      styled.cb_bb_db_sb_s_i_social,
                      styled.disable_highlight,
                    ].join(" ")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styled.cb_bb_input_box}>
            <div className={styled.cb_bb_ib_first_ceil}>
              <div className={styled.cb_bb_ib_fc_miny_input}>
                <input
                  type="text"
                  value={messageData.name}
                  id="name"
                  onChange={(e) => fillMessageBoxesHandler(e)}
                  placeholder="insert name"
                  className={[
                    styled.cb_bb_ib_fc_mi_input,
                    styled.highlight,
                  ].join(" ")}
                />
              </div>
              <div className={styled.cb_bb_ib_fc_miny_input}>
                <input
                  type="text"
                  value={messageData.subject}
                  id="subject"
                  onChange={(e) => fillMessageBoxesHandler(e)}
                  placeholder="insert subject"
                  className={[
                    styled.cb_bb_ib_fc_mi_input,
                    styled.highlight,
                  ].join(" ")}
                />
              </div>
            </div>
            <div className={styled.cb_bb_ib_second_ceil}>
              <div className={styled.cb_bb_ib_fc_miny_input}>
                <input
                  type="text"
                  value={messageData.email}
                  id="email"
                  onChange={(e) => fillMessageBoxesHandler(e)}
                  placeholder="insert email"
                  className={[
                    styled.cb_bb_ib_sc_mi_input,
                    styled.highlight,
                  ].join(" ")}
                />
              </div>
              <div className={styled.cb_bb_ib_fc_miny_input}>
                <input
                  type="text"
                  value={messageData.phone}
                  id="phone"
                  onChange={(e) => fillMessageBoxesHandler(e)}
                  placeholder="insert phone"
                  className={[
                    styled.cb_bb_ib_sc_mi_input,
                    styled.highlight,
                  ].join(" ")}
                />
              </div>
            </div>
            <div className={styled.cb_bb_ib_third_ceil}>
              <div className={styled.cb_bb_ib_fc_full_input}>
                <textarea
                  type="textarea"
                  value={messageData.message}
                  id="message"
                  onChange={(e) => fillMessageBoxesHandler(e)}
                  placeholder="insert message"
                  className={[styled.cb_bb_ib_tc_input, styled.highlight].join(
                    " "
                  )}
                />
              </div>
            </div>

            <div className={styled.cb_bb_ib_forth_ceil}>
              <button
                className={[
                  styled.cb_bb_ib_fc_button,
                  styled.disable_highlight,
                ].join(" ")}
                onClick={() => sendMessageHandler()}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
