import React, { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import Shayan from "../../assets/Svgs/shayan-black.svg";
import More from "../../assets/Svgs/more-black.svg";

import styled from "./index.module.css";

const Toolbar = (props) => {
  const [motion, setMotion] = useState(false);
  const [openToolbox, setOpenToolbox] = useState(false);

  const handleSections = (sec) => {
    if (sec === "") {
      setOpenToolbox(false);
    }
    //ANY
    setOpenToolbox(false);
  };

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y < -720) {
      setMotion(true);
    } else if (currPos.y > -720) {
      setMotion(false);
    }
  });

  return (
    <div
      className={[styled.toolbar, motion ? styled.show : styled.hide].join(" ")}
    >
      <div className={styled.toolbar_box}>
        <div className={styled.tb_logo_box}>
          <img
            src={Shayan}
            alt="shayan"
            className={[styled.tb_lb_logo, styled.disable_highlight].join(" ")}
          />
          <p
            className={[styled.tb_lb_title, styled.disable_highlight].join(" ")}
          >
            S
            <span
              className={[
                styled.tb_lb_line_through,
                styled.disable_highlight,
              ].join(" ")}
            >
              ll
            </span>
            ayan
          </p>
        </div>

        <div
          className={styled.tb_more_box}
          onClick={() => setOpenToolbox(!openToolbox)}
        >
          <img
            src={More}
            alt="more"
            className={[styled.tb_mb_icon, styled.disable_highlight].join(" ")}
          />
        </div>
      </div>

      {openToolbox && (
        <div className={styled.toolbox} onClick={() => handleSections("")}>
          <div className={styled.toolbox_box}>
            <img
              src={Shayan}
              alt="sllayan"
              className={[styled.tb_logo, styled.disable_highlight].join(" ")}
              onClick={() =>
                document
                  .querySelector("#beginning")
                  .scrollIntoView({ behavior: "smooth", block: "start" })
              }
            />
            <p
              className={[styled.tb_option, styled.disable_highlight].join(" ")}
              onClick={() =>
                document
                  .querySelector("#skills")
                  .scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Skills
            </p>
            <p
              className={[styled.tb_option, styled.disable_highlight].join(" ")}
              onClick={() =>
                document
                  .querySelector("#about")
                  .scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              About
            </p>
            <p
              className={[styled.tb_option, styled.disable_highlight].join(" ")}
              onClick={() =>
                document
                  .querySelector("#entertainment")
                  .scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Entertainment
            </p>
            <p
              className={[styled.tb_option, styled.disable_highlight].join(" ")}
              onClick={() =>
                document
                  .querySelector("#contact")
                  .scrollIntoView({ behavior: "smooth", block: "start" })
              }
            >
              Contact
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Toolbar;
