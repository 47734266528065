import React from "react";
import "./App.css";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Layout from "./HOC/Layout";
import Home from "./containers/Home";

const App = (props) => {
  let routes = (
    <Switch>
      <Route path="/" render={(props) => <Home {...props} />} />
      <Redirect to="/" />
    </Switch>
  );

  return (
    <Layout toolbar={true} goUp={true} footer={true}>
      {routes}
    </Layout>
  );
};

export default withRouter(App);
