import React, { useState } from "react";
import Typist from "react-typist";

import Sllayan from "../../assets/Svgs/shayan-white.svg";

import styled from "./index.module.css";

const Beginning = (props) => {
  const [typer, setTyper] = useState(true);

  return (
    <div className={styled.beginning} id={props.id}>
      <div className={styled.beginning_box}>
        <div
          className={[
            styled.bb_image_box,
            typer
              ? styled.bb_image_box_with_filter
              : styled.bb_image_box_without_filter,
          ].join(" ")}
        ></div>
        <div
          className={[
            styled.bb_text_box,
            typer ? styled.bb_text_box_captive : styled.bb_text_box_immune,
          ].join(" ")}
        >
          <img src={Sllayan} alt="s" className={styled.bb_tb_icon} />
          <p className={styled.bb_tb_first_text}>Welcome to my website</p>
          <p className={styled.bb_tb_second_text}>I develop 'n design</p>
          <p className={styled.bb_tb_third_text}>
            experiences that make people's lives simple.
          </p>
          <div
            className={[
              styled.bb_tb_type_text_box,
              typer ? "" : styled.bb_tb_type_text_box_close,
            ].join(" ")}
          >
            <Typist
              className={styled.bb_tb_ttb_text}
              avgTypingDelay={80}
              startDelay={200}
              onTypingDone={() => setTyper(false)}
              cursor={{
                show: true,
                blink: true,
                element: "_",
                hideWhenDone: false,
                hideWhenDoneDelay: 0,
              }}
            >
              <span>Web designer</span>
              <Typist.Backspace count={12} delay={1000} />
              <span>Back-end developer</span>
              <Typist.Backspace count={18} delay={1000} />
              <span>Front-end Developer</span>
              <Typist.Backspace count={19} delay={1000} />
            </Typist>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beginning;
