import React from "react";

import styled from "./index.module.css";

const Amaze = (props) => {
  return (
    <div className={styled.amaze}>
      <div className={styled.amaze_box}>
        <div className={styled.ab_text_box}>
          <p
            className={[styled.ab_tb_title, styled.disable_highlight].join(" ")}
          >
            Leave your site development to me, with ease mind!
          </p>
          <p
            className={[styled.ab_tb_text, styled.disable_highlight].join(" ")}
          >
            Count on me
          </p>
          <button
            className={[styled.ab_tb_button, styled.disable_highlight].join(
              " "
            )}
            onClick={() => window.open("tel:+989124585596", "_self")}
          >
            Contact with me
          </button>
        </div>
      </div>
    </div>
  );
};

export default Amaze;
