import React, { useState } from "react";

import Front from "../../../assets/Images/front.jpg";
import Back from "../../../assets/Images/back.jpg";
import International from "../../../assets/Images/international.jpg";
import Unique from "../../../assets/Images/unique.jpg";
import Dedicated from "../../../assets/Images/dedicated.jpg";
import FrontIcon from "../../../assets/Svgs/front.svg";
import BackIcon from "../../../assets/Svgs/back.svg";
import InternationalIcon from "../../../assets/Svgs/international.svg";
import UniqueIcon from "../../../assets/Svgs/unique.svg";
import DedicatedIcon from "../../../assets/Svgs/dedicated.svg";
import Bubble from "../../../assets/Svgs/bubble-orange.svg";

// import Bench from "../../assets/Svgs/bench-1.svg";

import styled from "./index.module.css";

const Info = (props) => {
  const INFO = [
    {
      title: "Front-end",
      text:
        "Using the strongest languages and new & latest technologies for designing variant versions of webs.",
      image: Front,
    },
    {
      title: "Back-end",
      text:
        "Programming different and variant as-to-web softwares with most usage and security using strongest tools and devices.",
      image: Back,
    },
    {
      title: "International Standards",
      text:
        "Having Frames and Worldwide standards in the field of programming and SEO",
      image: International,
    },
    {
      title: "Unique Design",
      text:
        "Special and spectacular unique designing in showing the individuald’s identity and job.",
      image: Unique,
    },
    {
      title: "Dedicated Programming",
      text:
        "Providing variant web sites and as-to-web softwares without any limits in the appearances and the facilities.",
      image: Dedicated,
    },
  ];
  const [activeInfo, setActiveInfo] = useState(3);

  const changeInfo = (id) => {
    infoAnimationClose(id);
    infoAnimationSlider(id);
    setTimeout(() => {
      setActiveInfo(id);
    }, 250);
    setTimeout(() => {
      infoAnimationOpen(id);
    }, 500);
  };
  const infoAnimationClose = (id) => {
    var image = document.getElementById("info_image");
    var icon = document.getElementById("info_icon");
    var title = document.getElementById("info_title");
    var text = document.getElementById("info_text");
    image.style.transition = "0.5s";
    icon.style.transition = "0.5s";
    title.style.transition = "0.5s";
    text.style.transition = "0.5s";
    image.style.transform = "translate(-100px, 0)";
    image.style.opacity = 0;
    icon.style.transform = "scale(0.002, 0.002)";
    icon.style.opacity = 0;
    title.style.transform = "translate(90%, 0)";
    title.style.opacity = 0;
    text.style.transform = "translate(0, 90%)";
    text.style.opacity = 0;
  };
  const infoAnimationOpen = (id) => {
    var image = document.getElementById("info_image");
    var icon = document.getElementById("info_icon");
    var title = document.getElementById("info_title");
    var text = document.getElementById("info_text");
    image.style.transition = "0.5s";
    icon.style.transition = "0.5s";
    title.style.transition = "0.5s";
    text.style.transition = "0.5s";
    image.style.transform = "translate(0, 0)";
    image.style.opacity = 1;
    icon.style.transform = "scale(1, 1)";
    icon.style.opacity = 1;
    title.style.transform = "translate(0, 0)";
    title.style.opacity = 1;
    text.style.transform = "translate(0, 0)";
    text.style.opacity = 1;
  };
  const infoAnimationSlider = (id) => {
    var s0 = document.getElementById("info_slider_0");
    var s1 = document.getElementById("info_slider_1");
    var s2 = document.getElementById("info_slider_2");
    var s3 = document.getElementById("info_slider_3");
    var s4 = document.getElementById("info_slider_4");
    s0.style.transition = "0.5s";
    s1.style.transition = "0.5s";
    s2.style.transition = "0.5s";
    s3.style.transition = "0.5s";
    s4.style.transition = "0.5s";
  };

  return (
    <div className={styled.info}>
      <div className={styled.info_box}>
        <div className={styled.ib_head_box}>
          <img
            src={Bubble}
            alt="w-e"
            className={[styled.ib_hb_icon, styled.disable_highlight].join(" ")}
          />
          <p
            className={[styled.ib_hb_title, styled.disable_highlight].join(" ")}
          >
            Info
          </p>
        </div>

        <div className={styled.ib_body_box}>
          <div className={styled.ib_bb_slider_box}>
            <div className={styled.ib_bb_sb_slider}>
              <div className={styled.ib_bb_sb_s_image_box}>
                <img
                  id="info_image"
                  src={INFO[activeInfo].image}
                  alt="design"
                  className={[
                    styled.ib_bb_sb_s_ib_image,
                    styled.disable_highlight,
                  ].join(" ")}
                />
              </div>
              <div className={styled.ib_bb_sb_s_text_box}>
                <img
                  id="info_icon"
                  src={
                    activeInfo === 0
                      ? FrontIcon
                      : activeInfo === 1
                      ? BackIcon
                      : activeInfo === 2
                      ? InternationalIcon
                      : activeInfo === 3
                      ? UniqueIcon
                      : activeInfo === 4
                      ? DedicatedIcon
                      : ""
                  }
                  alt="icon"
                  className={[
                    styled.ib_bb_sb_s_tb_logo,
                    styled.disable_highlight,
                  ].join(" ")}
                />
                <p
                  id="info_title"
                  className={[
                    styled.ib_bb_sb_s_tb_title,
                    styled.disable_highlight,
                  ].join(" ")}
                >
                  {INFO[activeInfo].title}
                </p>
                <p
                  id="info_text"
                  className={[
                    styled.ib_bb_sb_s_tb_text,
                    styled.disable_highlight,
                  ].join(" ")}
                >
                  {INFO[activeInfo].text}
                </p>
              </div>

              <div className={styled.ib_bb_sb_s_move_box}>
                <div
                  id="info_slider_0"
                  className={[
                    styled.ib_bb_sb_s_mb_number,
                    styled.disable_highlight,
                    activeInfo === 0 ? styled.ib_bb_sb_s_mb_active : "",
                  ].join(" ")}
                  onClick={() => changeInfo(0)}
                ></div>
                <div
                  id="info_slider_1"
                  className={[
                    styled.ib_bb_sb_s_mb_number,
                    styled.disable_highlight,
                    activeInfo === 1 ? styled.ib_bb_sb_s_mb_active : "",
                  ].join(" ")}
                  onClick={() => changeInfo(1)}
                ></div>
                <div
                  id="info_slider_2"
                  className={[
                    styled.ib_bb_sb_s_mb_number,
                    styled.disable_highlight,
                    activeInfo === 2 ? styled.ib_bb_sb_s_mb_active : "",
                  ].join(" ")}
                  onClick={() => changeInfo(2)}
                ></div>
                <div
                  id="info_slider_3"
                  className={[
                    styled.ib_bb_sb_s_mb_number,
                    styled.disable_highlight,
                    activeInfo === 3 ? styled.ib_bb_sb_s_mb_active : "",
                  ].join(" ")}
                  onClick={() => changeInfo(3)}
                ></div>
                <div
                  id="info_slider_4"
                  className={[
                    styled.ib_bb_sb_s_mb_number,
                    styled.disable_highlight,
                    activeInfo === 4 ? styled.ib_bb_sb_s_mb_active : "",
                  ].join(" ")}
                  onClick={() => changeInfo(4)}
                ></div>
              </div>

              <div className={styled.ib_bb_sb_s_ib_square_box_botton}>
                <div className={styled.ib_bb_sb_s_ib_sb_row}>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                </div>
                <div className={styled.ib_bb_sb_s_ib_sb_row}>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                </div>
                <div className={styled.ib_bb_sb_s_ib_sb_row}>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                </div>
              </div>
              <div className={styled.ib_bb_sb_s_ib_square_box_top}>
                <div className={styled.ib_bb_sb_s_ib_sb_row}>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                </div>
                <div className={styled.ib_bb_sb_s_ib_sb_row}>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                </div>
                <div className={styled.ib_bb_sb_s_ib_sb_row}>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                  <div className={styled.ib_bb_sb_s_ib_sb_c_square}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
