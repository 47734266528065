import React from "react";

import Toolbar from "../../components/Toolbar";
import GoUp from "../../components/GoUp";
import Footer from "../../components/Footer";

import styled from "./index.module.css";

const Layout = (props) => {
  return (
    <div>
      {props.toolbar && <Toolbar />}
      <main className={styled.layout}>{props.children}</main>
      {props.goUp && <GoUp />}
      {props.footer && <Footer />}
    </div>
  );
};

export default Layout;
